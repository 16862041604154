import React, { Component } from 'react';
import {  Popover } from 'antd';
import { Link } from 'react-router-dom';
import './contact.less';
import { BackTop } from 'antd';
import qrcodeImg from '../../img/common/footer/weixin_qrcode.png';
import qqImg from '../../img/common/float/btn_qq.svg';
import wxImg from '../../img/common/float/btn_wechat.svg';
import phoneImg from '../../img/common/float/iphone.svg';
class Contact extends Component {
    constructor(props) {
        super(props)
    };
    render() {
        const QQContent = (
            <div className="QQContent">
                <h3 style={{ textAlign: 'center', color: '#5D6494', fontSize: '14px', fontWeight: '400' }}>官方QQ群</h3>
                <p>译筑技术服务群：535878696</p>
            </div>
        )

        const WXContent = (
            <div className="WXContent">
                <h3 style={{ textAlign: 'center', color: '#5D6494', fontSize: '14px', fontWeight: '400' }}>微信公众号</h3>
                <img style={{ display: 'inline-block', width: '#110px', height: '110px' }} src={qrcodeImg} alt="译筑官微" />
            </div>
        )
        return (
            <div className="contact-float">
                 <div className='contact-content'>
                    <Link to={{pathname: "/about", active: "contactUs"}}>
                        <div className='contact-us'>
                            <img className="icon-img" src={phoneImg}/>
                            <span className='icon-txt'>联系我们</span>
                        </div>
                    </Link>
                    <Popover content={QQContent} placement="left">
                        <div className='contact-item content-item-one'>
                            <img className="icon-img" src={qqImg}/>
                        </div>
                    </Popover>
                    <Popover content={WXContent} placement="left">
                        <div className='contact-item content-item-two'>
                            <img className="icon-img" src={wxImg}/>
                        </div>
                    </Popover>
                </div>
            </div>)
    }
}
export default Contact