import { Layout } from "antd";
import React, { PureComponent } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import store from "../store/index";
import { developerRouters } from "./developerRouters";
import { publicRouters, routers } from "./routers";
// import Footer from '../common/component/Footer';
// import Header from '../common/component/Header';
import Contact from "../common/component/Contact";
import ScrollToTop from "./withRouter";

const COMPONENT_ID = "COMPONENT_ID";

let ROUTES = routers.concat(developerRouters, publicRouters);

export default class App extends PureComponent {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Layout>
            <Switch>
              <ScrollToTop>
                {ROUTES.map(
                  ({
                    path,
                    title,
                    exact,
                    isNav,
                    isConsole,
                    component: Component,
                  }) => (
                    <Route
                      key={path}
                      path={path}
                      exact={true}
                      render={(props) => {
                        document.title = `${
                          title === "首页" ? "EveryBIM 图形引擎" : title
                        }`;
                        return (
                          <Layout>
                            {/* <Header /> */}
                            <div id={COMPONENT_ID}>
                              <Component {...props} />
                            </div>
                            {/* <Footer /> */}
                            {isNav && !isConsole ? (
                              <div>
                                <Contact />
                                {/* <BackTop><span className="split-line"></span><div className="scrollTop"></div></BackTop> */}
                                <a href="#" className="ant-back-top" id="top">
                                  <span className="split-line"></span>
                                  <div className="scrollTop"></div>
                                </a>
                              </div>
                            ) : (
                              ""
                            )}
                          </Layout>
                        );
                      }}
                    />
                  )
                )}
              </ScrollToTop>
            </Switch>
          </Layout>
        </BrowserRouter>
      </Provider>
    );
  }
}
