import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';
import { USER_INFO, USER_AVATAR } from './actions';

const initState = fromJS({
    user_info: {}, // 用户信息
    user_avatar: null //用户头像
});
const handlers = {
    [USER_INFO]: (user, action) => {
        return user.set('user_info', fromJS(action.payload));
    },
    [USER_AVATAR]: (user, action) => {
        return user.set('user_avatar', fromJS(action.payload));
    },
};

export default createReducer(initState, handlers);