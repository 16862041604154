import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

export const FOOTER_VISIBLE = 'FOOTER_VISIBLE';

const initState = fromJS({
    footer_visible: false, // 系统配置
});
const handlers = {
    
    [FOOTER_VISIBLE]: (router, action) => {
        return router.set('footer_visible', fromJS(action.payload));
    },
};

export default createReducer(initState, handlers);