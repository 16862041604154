import { Map } from 'immutable';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { combineReducers } from 'redux-immutable';

import reducers from './reducers';


let middlewares = [];
middlewares.push(thunk);

// 添加中间件
let middleware = applyMiddleware(...middlewares);
// 添加redux dev tools
if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__());
}
const reducer = combineReducers(reducers);
const rootReducer = (state, action) => {
    return reducer(state, action)
};
const store = createStore(rootReducer, Map({}), middleware);
export default store;