import { ApiURL, oauthURL, oemKey } from "../../common/config/websiteConfig";
import rest from "../../common/utils/rest";

export const USER_INFO = "USER_INFO";
export const USER_AVATAR = "USER_AVATAR";

export function _concatUrl(url) {
  return `${ApiURL}/v1/account-service/${url}`;
}

function _hookError(error, message) {
  console.log(message + "出现错误 => ", error);
  throw error;
}

export function getUserToken(code) {
  let url = `${oauthURL}/api/user/token/${code}`;
  return rest
    .get(url, {})
    .then((result) => result)
    .catch((err) => _hookError(err, "获取token"));
}

//获取打开模型 token
export function getModelToken() {
  let url = `${ApiURL}/v1/token`;
  return rest
    .get(url)
    .then((result) => result)
    .catch((err) => _hookError(err, "获取模型token"));
}

//获取上传权限 token
export function getFileToken(fileInfo, type, headers) {
  let url = `${ApiURL}/v3/oss/access/${type}`;
  return rest
    .post(url, fileInfo, headers)
    .then((result) => result)
    .catch((err) => _hookError(err, "获取文件上传token"));
}

//存储模型数据
export function saveUserData(key) {
  let url = `${ApiURL}v3/info/key/${key}`;
  return rest
    .get(url)
    .then((result) => result)
    .catch((err) => _hookError(err, "存储模型数据"));
}

//获取存储的数据
export function getUserData(key) {
  let url = `${ApiURL}v3/info/key/${key}`;
  return rest
    .get(url)
    .then((result) => result)
    .catch((err) => _hookError(err, "获取存储的数据"));
}

export function getUserInfo(header) {
  return (dispatch) => {
    return rest
      .get(`${ApiURL}/${oemKey === "PRIVATE" ? "v3" : "v1"}/user/info`, header)
      .then((result) => {
        dispatch({
          type: USER_INFO,
          payload: result && result.data,
        });
        return result;
      })
      .catch((err) => _hookError(err, "获取应用信息"));
  };
}

//更新用户信息
export function updateUserInfo(data) {
  return (dispatch) => {
    return rest
      .post(`${ApiURL}/v1/user/update`, data)
      .then((result) => result)
      .catch((err) => _hookError(err, "更新用户信息"));
  };
}

export function getUserAvatar() {
  return (dispatch) => {
    return rest
      .get(`${ApiURL}/v1/user/head/image`)
      .then((result) => {
        dispatch({
          type: USER_AVATAR,
          payload: result && result.data,
        });
        return result;
      })
      .catch((err) => _hookError(err, "获取用户头像"));
  };
}

export function resetAppsecret() {
  let url = `${ApiURL}/v1/user/appSecret/reset`;
  return rest
    .get(url)
    .then((result) => result)
    .catch((err) => _hookError(err, "重置Appsecret"));
}

export function getLogout() {
  let url = `${ApiURL}/v1/user/logout`;
  return rest
    .get(url)
    .then((result) => result)
    .catch((err) => _hookError(err, "退出登录"));
}
