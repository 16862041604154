//开发者中心页面路由配置
import { oemKey } from "../common/config/websiteConfig";
import { AsyncComponent } from "../common/utils/AsyncComponent";
export const developerRouters =
  oemKey === "PRIVATE"
    ? []
    : [
        {
          path: "/developer",
          exact: true,
          isConsole: true,
          title: "控制台",
          component: AsyncComponent(() => import("../developer/console")),
        },
        {
          path: "/scene",
          exact: true,
          isNav: true,
          isConsole: true,
          title: "示例场景",
          component: AsyncComponent(() => import("../pages/scene")),
        },
        // {
        //     path: '/developGuide',
        //     exact: true,
        //     isNav:true,
        //     title: '开发指南',
        //     component: AsyncComponent(() => import("../developer/guide")),
        // },
        // {
        //     path: '/developDownload',
        //     exact: true,
        //     isNav:true,
        //     title: '下载',
        //     component: AsyncComponent(() => import("../common/component/Comingsoon")),
        // },
        // {
        //     path: '/supportCenter',
        //     exact: true,
        //     isNav:true,
        //     title: '支持中心',
        //     component: AsyncComponent(() => import("../developer/support")),
        // },
        {
          path: "/userInfo",
          exact: true,
          title: "个人中心",
          component: AsyncComponent(() => import("../developer/userInfo")),
        },
        {
          path: "/verifyPhone",
          exact: true,
          title: "修改手机号",
          component: AsyncComponent(() => import("../developer/verifyPhone")),
        },
      ];
